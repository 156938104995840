* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background: #F7F8FA;
  height: 100;

}

img {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 6px;
  height: 3px;
  border-top-left-radius: 60px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0000 linear-gradient(268deg, #3988f9, #3b3ad6) 0 0 no-repeat padding-box;
  border-radius: 10px;
}



a {
  text-decoration: none;
}


a {
  color: #3988f9;
}

.fc-r {
  color: #FF2357;
}

.fc-y {
  color: #FFB359;
}

.fc-b {
  color: #3988f9;
}

.fc-g {
  color: #5dd395;
}

.fc-gray {
  color: gray;
}



.fs-14 {
  font-size: 14px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-28 {
  font-size: 28px;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}





.border-bottom-gray {
  border-bottom: 1px solid rgba(128, 128, 128, 0.233);
}

.btn-dark-blue {
  font-size: 16px;
  font-weight: 500;
  background: linear-gradient(268deg, rgb(57, 136, 249), rgb(59, 58, 214)) 0px 0px no-repeat padding-box padding-box rgba(0, 0, 0, 0);
  color: #FFF !important;
  letter-spacing: 2px;
  border: none;
  border-radius: 50px;
  padding: 6px 14px !important;
  width: 100%;
}

.btn-dark-blue:hover {
  background: linear-gradient(268deg, rgb(57, 136, 249), rgb(59, 58, 214)) 0px 0px no-repeat padding-box padding-box rgba(0, 0, 0, 0);
  color: #FFF;
}

.btn:focus, .btn:active {
  outline: none !important;
  box-shadow: none;
}

.button::after {
  content: '';
  position: absolute;
  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%;
  background: linear-gradient(to bottom, rgba(229, 172, 142, 0), rgba(255, 255, 255, 0.5) 50%, rgba(229, 172, 142, 0));
  transform: rotateZ(200deg) translate(-5em, 7.5em);
}

.button {
  padding: 0 1em;
  position: relative;
  overflow: hidden;
  line-height: 40px;
}

.button::after, .button:focus::after {
  animation: sheen 3s forwards;
  animation-iteration-count: infinite;
}

@keyframes sheen {
  100% {
    transform: rotateZ(60deg) translate(1em, -13em);
    /*sets when animation stops and where*/
  }
}

.sidebar-logo-image {
  width: 130px;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 40px;
}

.sidebar-section-icons {
  font-size: 16px;
  margin-right: 6px;
  margin-bottom: 4px;
}

.sidebar-trade-text {
  text-transform: uppercase;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: .03em;
  font-weight: 600;
  padding-left: 20px;
}




.sidenav .sidebar-section {
  background: #0000 linear-gradient(268deg, #3989f946, #3a3ad634) 0 0 no-repeat padding-box;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  border-radius: 20px;
}

.sidenav .sidebar-section .nav-item:hover {
  background: #0000 linear-gradient(268deg, #3989f946, #3a3ad634) 0 0 no-repeat padding-box;
  border-radius: 20px;
}

/* .sidebar-section-swap{
    background: #0000 linear-gradient(268deg,#3988f9,#3b3ad6) 0 0 no-repeat padding-box;
    color: #FFF;
  } */

.sidenav .sidebar-section .nav-item .nav-link {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: .03em;
  font-weight: 550;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #0D6EFD;
  border-radius: 25px;
}

.sidenav .sidebar-section-2 {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  border-radius: 20px;
}

.sidenav .sidebar-section-2 .nav-item:hover {
  background: #0000 linear-gradient(268deg, #3989f946, #3a3ad634) 0 0 no-repeat padding-box;
  border-radius: 20px;
}

/* .sidebar-section-swap{
background: #0000 linear-gradient(268deg,#3988f9,#3b3ad6) 0 0 no-repeat padding-box;
color: #FFF;
} */

.sidenav .sidebar-section-2 .nav-item .nav-link {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: .03em;
  font-weight: 550;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #0D6EFD;
  border-radius: 25px;
}



.closebtn-icons {
  font-size: 20px;
  position: relative;
  left: -20px;
  bottom: -25px;
}

.openbtn-icons {
  font-size: 35px;
  color: #3988f9;
  padding: 4px;
  border: 1px solid #3989f970;
  margin-bottom: 12px;

}

.top-navbar-section .row>* {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.top-navbar-section ol, ul {
  padding-left: 0rem !important;
}

.home-banner-section .container-fluid {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.top-navbar-dropdown-icon {

  font-size: 18px;
  margin-bottom: 1px;
  margin-right: 10px;
}

.dropdown-item .dropdown-list-1 {
  padding: 0px !important;
  margin: 0px !important;
  font-size: 13px !important;
  font-weight: 700;
  text-transform: uppercase;
}

.dropdown-item .dropdown-list-2 {
  padding: 0px !important;
  margin: 0px !important;
  font-size: 11px !important;
  text-transform: uppercase;
}

.dropdown-menu {
  padding: 3px !important;
}

.dropdown-item {
  padding: 10px 20px;
  width: 180px;
}


.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon) !important;
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button::after {

  margin-left: 10px !important;
}

.from-section-coin-background {
  padding-top: 3px;
  width: max-content;
}

.from-section-coin-background:hover {
  border-radius: 20px;
  background: rgb(255, 255, 255);
}

#Swap-coin-select-section .modal-dialog-scrollable .modal-content {
  max-height: 70% !important;
  overflow: hidden;
}

.modal-backdrop {
  background-color: transparent !important;
}

.Swap-coin-select-section-modal {
  background-image: url("./images/BLUR2.jpg");
}

@font-face {
  font-family: Acme;
  src: url("./images/fonts/Acme-Regular.ttf");
}

.border-right-dotted {
  border-right: 1px dotted #000;
}

.dashboard-top-section .swiper {
  width: 100%;
  height: 100%;
}

.dashboard-top-section .swiper-slide {
  background: #fff;
  border-radius: 1.5rem;
  padding: 15px 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  /* Center slide text vertically */

  -webkit-align-items: center;
  align-items: center;
}

.dashboard-top-section .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dashboard-top-section .swiper-button-next, .swiper-button-prev {
  color: gray !important;
}

.dashboard-top-section .swiper-button-next:hover, .swiper-button-prev:hover {
  color: #3988f9 !important;
}

.dashboard-top-section .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  opacity: 0 !important;
}

.dashboard-top-section .swiper-button-next:after, .swiper-button-prev:after {
  font-size: 24px !important;
  font-weight: bold;
}

.dashboard-border-bottom {
  border-bottom: 2px solid #000;
}

.staking-border-bottom {
  border-bottom: 1px solid rgba(128, 128, 128, 0.26);
}

.navbar {
  font-weight: 300;
  box-shadow: none !important;
}

.navbar-brand {
  width: 100px;
}

.home-banner-section-top .nav-link {
  font-size: 16px;
  text-transform: uppercase;
  color: #FFF;

  letter-spacing: 1px;
}

/* .home-banner-section{
  background-image: url("https://www.sleepiest.com/assets/img/hero/2.png") !important;
  background: #554df1;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
} */

@font-face {
  font-family: Jost;
  src: url("./images/fonts/Jost-VariableFont_wght.ttf");
}

@font-face {
  font-family: SecularOne;
  src: url("./images/fonts/SecularOne-Regular.ttf");
}

@font-face {
  font-family: ArchivoBlack;
  src: url("./images/fonts/ArchivoBlack-Regular.ttf");
}

@font-face {
  font-family: FredokaOne;
  src: url("./images/fonts/FredokaOne-Regular.ttf");
}

/* .home-banner-section-top {
  font-family: "Jost";
  background-color: #061A4A;
  color: #FFF;
} */
.btn-close {
  box-shadow: none !important;
}

.offcanvas-backdrop {
  --tw-backdrop-blur: blur(50px);
  background: #FFF;
}



.footer-community-column {
  display: flex;
  flex-direction: column;
}

.top-banner-section {
  padding-top: 25vh;
}

@media (min-width: 576px) {
  #connect-wallet-select-section .modal-dialog {
    max-width: 550px;
    margin-right: auto;
    margin-left: auto;
  }
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {
 .offcanvas-header{
   display: none;
 }
  .modal-header-heading {
    position: relative;
    top: -50px;
    padding: 0px;
  }
  .connected-text-send{
    background-color: #EDEEF2;
    border: none;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 10px;
    color: #3988f9;
  }
  .connected-wallet-token-section:hover{
    background-color: #3697f246;
  }
  .connected-text-6{
    font-size: 16px;
    font-weight: 500;
    margin-top: 8px;
  }
  .connected-text-5{
    font-size: 12px;
    font-weight: 500;
  }
  .connected-text-4{
    font-size: 12px;
    font-weight: 900;
    margin-left: 4px;
    margin-bottom: -6px !important;

  }
  .connected-wallet-token-section img {
    width: 30px;
    height: 30px;
  }

  .connected-text-copy {
    background: transparent;
    border: 0px solid rgb(233 133 102) !important;
    margin-left: 10px;
  }

  .connected-copy-icon {
    --tw-text-opacity: 1;
    color: rgb(124 133 159 / var(--tw-text-opacity));
    font-size: 14px;
    margin-bottom: 2px;
  }

  .connected-text-3 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 50px;
    vertical-align: middle;
    font-size: 12px;
  }

  .connected-text-2 {
    font-size: 12px;
    font-weight: 900;
    margin-left: 4px;
  }

  .connected-wallet-metamask {
    width: 20px;
    height: 20px;
  }

  .connected-wallet-display-section {
    background-color: #EDEEF2;
    padding-top: 8px;
    padding-bottom: 10px;
  }

  .connected-reload-button {
    margin-bottom: 4px;
  }

  .connected-text-reload-2 {
    background: transparent;
    font-size: 16px;
    color: #3988f9;
    border: none !important;
    padding-left: 6px;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-right: 6px;
    border-radius: 6px;
  }

  .connected-text-reload {
    background: transparent;
    font-size: 20px;
    color: #3988f9;
    border: none !important;
    margin-left: 12px;
    padding-left: 6px;
    padding-top: 1px;
    padding-bottom: 2px;
    padding-right: 6px;
    border-radius: 6px;
  }

  .connected-text-reload:hover {
    color: #fff;
    background: #3697f2a6;
  }

  .connected-text-add {
    background: transparent;
    font-size: 20px;
    color: #7bddd3;
    border: 1px solid #7bddd3 !important;
    margin-left: 12px;
    padding-left: 6px;
    padding-top: 1px;
    padding-bottom: 2px;
    padding-right: 6px;
    border-radius: 6px;
  }

  .connected-text-add:hover {
    background: #7bddd32c;
    font-size: 20px;
    color: #7bddd3;
    border: 1px solid #7bddd3 !important;
  }

  .connected-text-disconnect {
    background: transparent;
    font-size: 20px;
    color: rgb(233 133 102);
    border: 1px solid rgb(233 133 102) !important;
    margin-left: 12px;
    padding-left: 6px;
    padding-top: 1px;
    padding-bottom: 2px;
    padding-right: 6px;
    border-radius: 6px;
  }

  .connected-text-disconnect:hover {
    background: rgba(233, 133, 102, 0.281);
    font-size: 20px;
    color: rgb(233 133 102);
    border: 1px solid rgb(233 133 102) !important;
  }

  .connected-text-1 {
    font-family: SecularOne;
    font-size: 21px;
    line-height: 17px;
    font-weight: 900;
  }

  .connect-wallet-active {
    border: 1px solid #3988f9 !important;
    background-color: #96bff88e !important;
  }

  .eth-connect-wallet-button, .bsc-connect-wallet-button, .wyzth-connect-wallet-button {
    background-color: transparent;
    border: 1px solid rgba(128, 128, 128, 0.247);
    width: 100%;
    padding-top: 4px;
    border-radius: 12px;
  }

  .connect-wallet-section img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .connect-wallet-text-1 {
    font-size: 12px;
    font-weight: 600;
    margin: 0px;
  }

  .connect-wallet-text-2 {
    font-size: 12px;
    font-weight: 400;
    position: relative;
    top: -4px;
  }

  #myVideo {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    min-height: 100vh;
    opacity: 0.5;
  }

  .footer-bottom-section {
    border-top: 1px solid rgba(255, 255, 255, 0.048);
    padding-top: 50px !important;
    padding-bottom: 100px;
    margin-top: 100px;
  }

  .footer-text-1 {
    font-size: 30px !important;
    font-weight: 400 !important;
    letter-spacing: 2px !important;
    margin-bottom: 20px;
  }

  .hero-banner-section-1 {
    font-family: "FredokaOne";
    font-size: 50px;
    color: #FFF;
    margin-bottom: 0px;
  }

  .hero-banner-section-2 {
    font-family: "Jost";
    font-size: 22px;
    color: #FFF;
  }



  .heading-before-after {
    font-size: 2.5em;
    /* 	margin-bottom: 2em; */
    text-transform: uppercase;
    letter-spacing: 10px;
    text-align: center;
    font-family: Lora, serif;
    font-weight: 200;
  }

  .heading-before-after::before {
    content: '─';
    margin-right: 0.6em;
    color: #3988f9;
  }

  .heading-before-after::after {
    content: '─';
    margin-left: 0.6em;
    color: #3988f9;
  }

  .boxes {
    display: flex;
    justify-content: space-evenly;
    margin: 50px;
  }

  .boxes:last-child {
    margin-top: 120px;
  }

  .box {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background: linear-gradient(180deg, rgba(35, 48, 75, .32) 0, #071E64 100%);
    -webkit-backdrop-filter: blur(32px);
    backdrop-filter: blur(32px);
    border-radius: 32px;

    position: relative;
    text-align: center;
    z-index: 3;

  }

  .second-section-card-images-1 {
    width: 150px;
    margin-top: -80px;
    padding-bottom: 40px;
  }

  .second-section-card-images-2 {
    width: 210px;
    margin-top: -110px;
    padding-bottom: 10px;
  }


  .box:last-child {
    border-right: 0;
  }

  .box .icon {
    font-size: 8em;
    color: #3988f9;
    bottom: 50px;
    position: relative;

  }

  .box h2 {
    font-size: 2em;
    margin-bottom: 1em;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-weight: 600;
  }

  .box .piece-count {
    margin-bottom: 1.6em;
    letter-spacing: 1px;
    font-family: "Jost";
    font-size: 2.1em;
    font-weight: 900;
  }

  .piece-count-para {
    font-size: 1em;
  }

  /* ====================================FOOTER========================================== */
  .card-bottom-section img {
    width: 40%;
    height: 180px;
    margin: auto;
  }

  .card-bottom-section h1 {
    margin-bottom: 20px;
    font-size: 1.3rem;
  }

  .footer-bottom-section img {
    width: 40%;
  }

  .footer-bottom-section h1 {
    font-size: 24px;
    letter-spacing: 1px;
    margin-bottom: 34px;
    font-weight: 700;
  }

  .footer-community-icons {
    font-size: 18px;
    color: white;
    margin-bottom: 4px;
    margin-right: 10px;
    transition: transform .2s;
    /* Animation */
  }

  .footer-icons-heading:hover .footer-community-icons {
    transform: scale(1.4);
  }

  .footer-icons-heading {
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-bottom: 15px;
  }

  .footer-icons-heading:hover {
    color: rgb(255, 255, 255);
  }

  .two-row-1 {
    margin-bottom: 6em;
    text-align: justify;
  }

  .two-row-heading-para-style {
    font-size: 1.6em;
  }

  .two-row-heading-style {
    color: #f6de7f;
  }

  .two-row-para-style {
    color: rgb(223, 223, 223);
    font-size: 1em;
  }

  .mobile-image-styling-banner-page {
    width: 100%;
    height: 100%;
  }

  .top-hero-section-heading {
    font-family: "Jost";
    font-size: 45px;
    line-height: 60px;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .top-hero-section-star-image {
    width: 40px;
    height: 40px;
    margin-top: -70px;
    margin-right: 10px;
  }

  .ml-150 {
    margin-left: 150px;
  }

  .home-card-section .card {
    width: 80%;
    background: #071E64;
    border: 0px;
    border-radius: 15px;
  }

  .home-card-section .card img {
    width: 120px;
    height: 120px;
    position: absolute;
    top: -50px;
  }



  .launch-button-navbar {
    font-family: "Jost";
    color: #3697F2 !important;
    background-color: transparent;
    border-radius: 10rem;
    border: 1px solid #3697F2;
    padding: 10px 40px !important;
    width: fit-content;
    font-weight: 600;
    font-size: 14px !important;
    letter-spacing: 2px !important;
    transition: all .3s ease-in-out;
  }

  .launch-button-navbar:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px 0 #3697F2
  }

  .swap-button-card {
    font-family: "Jost";
    color: #3697F2 !important;
    background-color: transparent;
    border-radius: 10rem;
    border: 1px solid #3697F2;
    padding: 10px 40px !important;
    width: fit-content;
    font-weight: 700;
    font-size: 18px !important;
    letter-spacing: 2px !important;
    transition: all .3s ease-in-out;
  }

  .swap-button-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px 0 #3697F2
  }

  .launch-button-herobanner {
    font-family: "Jost";
    color: #fff;
    background-color: #3697f2a6;
    border-radius: 10rem;
    padding: 10px 40px !important;
    width: fit-content;
    font-weight: 700;
    transition: transform 3s;
    /* Animation */
    margin: auto;
  }

  .launch-button-herobanner:hover {
    background: linear-gradient(56deg, #61dafb 0%, #d6cbf6 46%, #ee70e8 100%);
    color: #fff;
    transform: scale(1.30);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .launch-button-herobanner .launch-button-herobanner-image-1 {
    width: 0px;
    height: 0px;
    position: absolute;
    left: 30px;
    top: 40px;
    transition: 1s;
  }

  .launch-button-herobanner:hover .launch-button-herobanner-image-1 {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 0px;
    top: 0px;
    left: 110px;
  }

  .launch-button-herobanner .launch-button-herobanner-image-2 {
    width: 0px;
    height: 0px;
    position: absolute;
    right: 50px;
    top: 30px;
    transition: 2s;
  }

  .launch-button-herobanner:hover .launch-button-herobanner-image-2 {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 90px;
    top: 30px;
  }


  .hover_effect {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: block;
    width: 100%;
    padding: 1px;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
  }

  .hover_effect:after {
    content: "";
    height: 2px;
    left: 0;
    width: 1px;
    position: absolute;
    -webkit-transition: width 0.4s ease, background-color 0.4s ease;
    -moz-transition: width 0.4s ease, background-color 0.4s ease;
    ;
    -ms-transition: width 0.4s ease, background-color 0.4s ease;
    ;
    -o-transition: width 0.4s ease, background-color 0.4s ease;
    ;
    transition: width 0.4s ease, background-color 0.4s ease;
    ;
    right: 0;
    margin: 0 auto;
  }

  .hover_effect:hover {
    color: #000;
    -webkit-transition: color 0.4s ease;
    -moz-transition: color 0.4s ease;
    -ms-transition: color 0.4s ease;
    -o-transition: color 0.4s ease;
    transition: color 0.4s ease;
    cursor: pointer;
  }

  .hover_effect:hover:after,
  .hover_effect:focus:after {
    width: 100%;
    background: #0000 linear-gradient(268deg, #3988f9, #3b3ad6) 0 0 no-repeat padding-box;
  }

  .dashboard-table-swap-button {
    background: transparent;
    outline: none;
    border: 1px solid #3988f9;
    padding: 8px 16px;
    border-radius: 12px;
    font-size: 11px;
    font-weight: 600;
  }

  .dashboard-table-swap-button:hover {
    background: #3989f944;
    border: 1px solid #3988f9;
  }

  .dashboard-table-liquidity-button {
    background: transparent;
    outline: none;
    border: 1px solid #7B48E8;
    padding: 8px 16px;
    border-radius: 12px;
    font-size: 11px;
    font-weight: 600;
  }

  .dashboard-table-liquidity-button:hover {
    background: #7b48e838;
    border: 1px solid #7B48E8;
  }

  .dashboard-section-swap-button {
    background: transparent;
    outline: none;
    border: 1px solid #3988f9;
    padding: 8px 16px;
    width: 100%;
    border-radius: 12px;
    font-size: 11px;
    font-weight: 600;
  }

  .dashboard-section-swap-button:hover {
    background: #3989f944;
    border: 1px solid #3988f9;
  }

  .dashboard-section-liquidity-button {
    background: transparent;
    outline: none;
    border: 1px solid #7B48E8;
    padding: 8px 16px;
    width: 100%;
    border-radius: 12px;
    font-size: 11px;
    font-weight: 600;
  }

  .dashboard-section-liquidity-button:hover {
    background: #7b48e838;
    border: 1px solid #7B48E8;
  }

  .dashboard-section-text-5 {
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0rem !important;
  }

  .dashboard-section-text-4 {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0.2rem !important;
  }

  .dashboard-section-text-3 {
    font-size: 28px;
    letter-spacing: .02em;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .staking-section-text-5 {
    font-size: 15px;
    font-weight: 400;
    color: #7c859f;
  }

  .staking-section-text-4 {
    font-size: 16px;
    margin-bottom: 0.6rem;
    font-weight: 500;
  }

  .staking-info-icon-2 {
    font-size: 16px;
    color: #3988f9;
    margin-bottom: 4px;
    margin-left: 4px;
  }

  .staking-section-text-3 {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: .03em;
    font-weight: 500;
    color: #7c859f;
  }

  .your-stake-details-section {
    background-color: #E6F2F7;
    padding: 12px 22px;
    border: 1px solid #67cefa;
    border-radius: 1rem;
  }

  .staking-section-text-2 {
    font-size: 17px;
    line-height: 25px;
    letter-spacing: -.01em;
    font-weight: 600;
  }

  .staking-section-addwyzth-button {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #000 !important;
    background-color: transparent;
    border: 1px solid gray;
    padding: 6px 14px;
    border-radius: 1rem;
  }

  .metamask-logo {
    height: 20px;
    width: 20px;
    margin-bottom: 4px;
  }

  .staking-reload-icon {
    font-size: 20px;
    color: #7c859f;
  }

  .staking-info-icon {
    font-size: 20px;
    color: #3988f9;
  }

  .staking-section-text-1 {
    font-size: 12px;
    font-weight: 400;
    color: #7c859f;
  }

  .volume-breadcrumb a {
    color: #7c859f;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
  }

  .volume-breadcrumb a:hover {
    border-bottom: 1px solid gray;
  }

  .liquidity-breadcrumb a {
    color: #7c859f;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
  }

  .liquidity-breadcrumb a:hover {
    border-bottom: 1px solid gray;
  }

  .dashboard-section-week-button-1, .dashboard-section-all-button-1, .dashboard-section-week-button-2, .dashboard-section-all-button-2 {
    background: transparent;
    outline: none;
    border: 1px solid rgba(128, 128, 128, 0.658);
    padding: 8px 16px;
    border-radius: 12px;
    font-size: 11px;
    font-weight: 600;
  }

  .dashboard-section-heading-1 {
    font-family: Acme;
    font-size: 30px;
    font-weight: 1000;
  }

  .dashboard-section-heading-2 {
    font-family: sans-serif;
    font-size: 26px;
    font-weight: 1000;
    margin-left: 10px;
  }

  .dashboard-section-heading-3 {

    font-size: 16px;
    margin-left: 10px;
    font-weight: 500;
  }

  .dashboard-top-section-Swiper-Slide-1:hover .dashboard-section-text-1 {
    color: #FFF !important;
  }

  .dashboard-top-section-Swiper-Slide-1:hover {
    background-color: #F8B35A;
    color: #FFF !important;
  }

  .dashboard-top-section-Swiper-Slide-2:hover .dashboard-section-text-1 {
    color: #FFF !important;
  }

  .dashboard-top-section-Swiper-Slide-2:hover {
    background-color: #7B48E8;
    color: #FFF !important;
  }

  .dashboard-top-section-Swiper-Slide-3:hover .dashboard-section-text-1 {
    color: #FFF !important;
  }

  .dashboard-top-section-Swiper-Slide-3:hover {
    background-color: #ED6B82;
    color: #FFF !important;
  }

  .dashboard-top-section-Swiper-Slide-4:hover .dashboard-section-text-1 {
    color: #FFF !important;
  }

  .dashboard-top-section-Swiper-Slide-4:hover {
    background-color: #3697F2;
    color: #FFF !important;
  }

  .dashboard-section-text-2 {
    font-size: 15px;
    letter-spacing: -.02em;
    font-weight: 900;
    margin-bottom: 0px;
  }

  .swiper-slide-icons-info {
    font-size: 14px;
    margin-bottom: 4px;
    margin-left: 4px;
  }

  .dashboard-section-text-1 {
    font-size: 12px;
    letter-spacing: .03em;
    font-weight: 700;
    color: gray;
    margin-bottom: 0.8rem;
  }

  .swiper-slide-icons-4 {
    font-size: 40px;
    background-color: #E7F1FB;
    padding: 25px 6px;
    height: 100%;
    margin-right: 10px;
    border-radius: 30px;
    color: #3697F2;
  }

  .swiper-slide-icons-3 {
    font-size: 40px;
    background-color: #FAEDEF;
    padding: 25px 6px;
    height: 100%;
    margin-right: 10px;
    border-radius: 30px;
    color: #ED6B82;
  }

  .swiper-slide-icons-2 {
    font-size: 40px;
    background-color: #EFE9FA;
    padding: 25px 6px;
    height: 100%;
    margin-right: 10px;
    border-radius: 30px;
    color: #7B48E8;
  }

  .swiper-slide-icons-1 {
    font-size: 40px;
    background-color: #FCF4EB;
    padding: 25px 6px;
    height: 100%;
    margin-right: 10px;
    border-radius: 30px;
    color: #F8B35A;
  }

  .Swiper-Slide-1 {
    background: #000;
  }

  #wallet-top-banner-section ::placeholder {
    font-size: 15px;
  }

  .form-control-wallet-section {
    box-shadow: none;
    outline: none;
    display: block;
    width: 100%;
    padding: 0.375rem 0.15rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0rem;
    border-left: none;
    width: 70%;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  #wallet-top-banner-section .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.55rem;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    border-radius: 0px;
    border-right: none;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  .wallet-button-grid-list-section {
    border: 1px solid rgba(0, 0, 0, 0.473);
    width: fit-content;
    border-radius: 16px;
  }

  .wallet-button-grid-list-1 {
    font-size: 18px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-top: 2px;
    border: none;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .wallet-button-grid-list-2 {
    font-size: 18px;
    padding-bottom: 8px;
    padding-right: 16px;
    padding-top: 2px;
    border: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .truncate-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 60px;
    vertical-align: middle;
  }

  .wallet-section-text-8 {
    font-size: 18px;
    font-weight: 600;
    margin: 0px;
  }

  .list-view-all-headings {
    font-size: 11px;
    line-height: 18px;
    letter-spacing: .03em;
    font-weight: 700;
    color: #7c859f;
  }

  .wallet-section-text-9 {
    font-weight: 900;
    margin-left: 4px;
    font-size: 15px;
    font-family: sans-serif;
  }


  #wallet-top-banner-section .list-view .symboloverview-widget-section {
    height: 100px !important;
    margin-top: -40px;
  }

  #wallet-top-banner-section .grid-container .wallet-section-text-4 {
    font-size: 24px;
    letter-spacing: .025em;
    font-weight: 600;
    margin-bottom: 0px;
  }

  #wallet-top-banner-section .list-view .wallet-section-text-4 {
    font-size: 16px;
    letter-spacing: .025em;
    font-weight: 600;
    margin-bottom: 0px;
  }

  #wallet-top-banner-section .list-view .wallet-section-bottom-border {
    display: none;
  }

  #wallet-top-banner-section .grid-container .list-view-all-headings {
    display: none;
  }

  #wallet-top-banner-section .grid-container .top-amount-section {
    display: none;
  }

  #wallet-top-banner-section .list-view .top-amount-section {
    display: block;
  }

  #wallet-top-banner-section .list-view .list-view-all-headings {
    display: block;
  }

  #wallet-top-banner-section .list-view .default-coin-name-section-2 {
    padding-left: 30px;
  }

  #wallet-top-banner-section .grid-container .default-coin-name-section-2 {
    padding-left: 10px;
  }

  .wallet-section-bottom-border {
    border-top: 1px solid rgba(0, 0, 0, 0.247);
  }

  .wallet-section-text-7 {
    font-size: 18px;
    letter-spacing: .03em;
    font-weight: 600;
    color: #7c859f;
  }

  .wallet-section-buttons-section:hover .wallet-section-text-6 {
    color: rgba(0, 0, 0, 0.808);
  }

  .wallet-section-buttons-section:hover .wallet-section-buttons {
    color: rgba(0, 0, 0, 0.808);
  }

  .wallet-section-text-6 {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: .04em;
    font-weight: 500;
    color: rgb(128, 128, 128) !important;
    margin-top: 6px !important;

  }
  .wallet-section-buttons-section .btn{

    padding-top: 0px !important;
    color: rgb(128, 128, 128) !important;
  }

  .wallet-section-buttons {
    background-color: #d8d9db !important;
    border: 0px;
    width: 4rem !important;
    border-radius: 50px;
    font-size: 30px;
    color: rgba(128, 128, 128, 0.719);
    padding-bottom: 8px !important;
  }


  .wallet-section-text-5 {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: .03em;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .wallet-section-text-3 {
    position: relative;
    top: -8px;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: .03em;
    font-weight: 450;
    color: #7c859f;
  }

  .wallet-section-text-2 {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: .03em;
    font-weight: 500;
  }

  .wallet-section-logo {
    margin-top: 4px;
    width: 35px;
    height: 35px;
  }

  .wallet-section-card-button-section {
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.247);
    margin-bottom: 10px;
  }

  .wallet-section-card {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #FFF;
    padding: 10px 20px;
    border-radius: 15px;
    border: 1px solid transparent;
  }

  .wallet-section-card:hover {
    border: 1px solid rgba(128, 128, 128, 0.411);
  }

  .disconnect-list-button {
    border: 1px solid rgb(233 133 102);
    font-size: 18px;
    padding: 10px;
    padding-top: 3px;
    padding-bottom: 8px;
    color: rgb(233 133 102);
    border-radius: 10px;
    background: transparent;
  }

  .disconnect-list-button:hover {
    border: 1px solid rgb(233 133 102);
    color: rgb(233 133 102);
  }

  .reload-list-button {
    border: 1px solid #3697F2;
    font-size: 18px;
    padding: 10px;
    padding-top: 3px;
    padding-bottom: 8px;
    color: #3697F2;
    border-radius: 10px;
    background: transparent;
  }

  .reload-list-button:hover {
    border: 1px solid #3697F2;
    color: #3697F2;
  }

  .connect-wallet-list-button {
    background: #3697F2 !important;
    color: #fff !important;
    font-size: 14px;
    font-weight: 700;
    border-radius: 14px;
    padding: 10px 18px;
    border: none;
  }

  .connect-wallet-list-button:hover {
    background: #3697F2 !important;
    color: #fff;
    border: none;
  }

  .wallet-section-text-1 {
    font-size: 15px;
    line-height: 21px;
    letter-spacing: .03em;
    font-weight: 500;
  }

  #wallet-top-banner-section .card {
    background-color: transparent;
    border: none;
  }

  #wallet-top-banner-section .list-view .card {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    border: none;
    border-radius: 20px;
  }

  #wallet-top-banner-section .list-view .card:hover {
    background-color: rgba(128, 128, 128, 0.103);
    border: none;
  }

  #wallet-top-banner-section .card-body {
    padding: 0px !important;
  }

  .more-info-send-accordian .accordion-item {
    border: none !important;
    margin-right: 3px;
    margin-left: 3px;
    background-color: #F0F1F3 !important;
    border-radius: 15px !important;

  }

  .more-info-send-accordian .accordion-button {
    outline: none !important;
    box-shadow: none !important;
    background-color: #F0F1F3 !important;
    padding: 15px 15px;
    border-radius: 12px !important;
  }

  .more-info-send-accordian .accordion-button:not(.collapsed) {
    color: #000;
  }

  .send-section-address ::placeholder {
    color: #7c859f;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .more-info-deposit-accordian-text-1 {
    font-size: 13px;
    color: #7C859F;
    font-weight: 500;
  }

  .more-info-deposit-accordian-text-2 {
    font-size: 18px;
    letter-spacing: -.01em;
    font-weight: 800;
    font-family: Poppins, sans-serif;
  }

  .balance-text-1 {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: .03em;
    font-weight: 500;
    color: #7c859f;
  }

  .from-section-deposit-top {
    background-color: #F0F1F3;
    padding: 10px;
    border-radius: 15px;
    border: 1px solid transparent;
  }

  .deposit-switch-tabs-section-button {
    border: 1px solid #7c859f2d;
    border-radius: 12px;
    padding: 4px;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .deposit-switch-tabs-section .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #FFF;
    background-color: #3988f9;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 600;
    padding: 10px;
    letter-spacing: 1px;
    color: #FFF !important;
  }

  .deposit-switch-tabs-section .nav-pills .nav-link {
    font-size: 12px;
    font-weight: 600;
    padding: 10px;
    letter-spacing: 1px;
    border-radius: 12px;
    color: #000 !important;
  }

  .token-select-section {
    background-color: #ECECEC;
  }

  #Swap-coin-select-section .list-group-item {
    padding-top: 5px;
    padding-bottom: 5px;
    text-decoration: none;
    background-color: transparent;
    border: 0px;
  }

  #Swap-coin-select-section .list-group-item:hover {
    background-color: #FFF;
  }

  .token-select-image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  .token-select-section-icon {
    font-size: 24px;
    margin-bottom: 2px;
  }

  .token-select-section-text-1 {
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }

  .token-select-section-text-2 {
    font-size: 12px;
    font-weight: 400;
    position: relative;
    top: -9px;
    text-transform: uppercase;
  }

  .swap-select-form-filter-section {
    margin-left: 4px;
    margin-right: 4px;
    border: 1px solid #7c859f2d;
    border-radius: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .swap-select-form-filter-button-1, .swap-select-form-filter-button-2, .swap-select-form-filter-button-3, .swap-select-form-filter-button-4, .swap-select-form-filter-button-5 {
    background: transparent;
    outline: none;
    border: none;
    padding: 10px 10px;
    width: 20%;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 600;
  }

  #Swap-coin-select-section .btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.55em 0.55em;
    color: #000;
    border: 1px solid #000;
    border-radius: 0.375rem;
    opacity: .5;
    margin-right: 10px;
    box-shadow: none !important;
  }

  #Swap-coin-select-section ::placeholder {
    font-size: 15px;
    font-weight: 400;
    text-transform: capitalize;
  }

  .swap-select-form {
    outline: none;
    display: block;
    width: 100%;
    padding: 0.675rem 0.75rem;
    padding-bottom: 14px;
    font-size: 1rem;
    font-weight: 400;
    color: #212529;
    background-color: #F0F1F3;
    border: 1px solid #ced4da;
    border-radius: 1.5rem;
    padding-left: 30px;
  }

  .swap-coin-select-text-1 {
    font-family: Acme;
    font-style: italic;
    font-size: 22px;
    letter-spacing: 2px;
    font-weight: 800;
    text-transform: uppercase;
    padding: 0px !important;
    margin: 0px !important;
    padding-left: 10px !important;
  }

  .slippage-settings-section .modal-dialog {
    max-width: 400px;
    position: relative;
    right: -3%;
    top: -6%;
  }


  .slippage-settings-section .modal-content {
    padding: 10px;
    border-radius: 15px;
  }

  .slippage-settings-text-1 {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: .03em;
    font-weight: 700;
  }

  .slippage-settings-text-2 {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: .03em;
    font-weight: 700;
    color: #7c859f;
  }

  .slippage-icon-text-1 {
    font-size: 16px;
    color: #7c859f;
    margin-bottom: 3px;
    margin-left: 6px;
  }

  .slippage-settings-button-05, .slippage-settings-button-3, .slippage-settings-button-1 {
    background: transparent;
    outline: none;
    border: 1px solid #7c859f;
    padding: 8px 20px;
    border-radius: 1rem;
    margin-right: 8px;
    font-size: 14px;
    font-weight: 900;
  }

  .fee-normal-button, .fee-fast-button, .fee-instant-button {
    background: transparent;
    outline: none;
    border: 1px solid #7c859f;
    padding: 8px 20px;
    border-radius: 1rem;
    margin-right: 8px;
    font-size: 14px;
    font-weight: 900;
  }



  .slippage-settings-section ::placeholder {
    color: rgba(0, 0, 0, 0.616) !important;
    font-size: 14px;
    font-weight: 900;
  }

  .form-slippage-percentage {
    width: 80%;
    padding: 8px 0px;
    text-align: right;
    font-size: 14px;
    font-weight: 900;
    color: #000;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  }

  .slippage-settings-text-3 {
    font-size: 14px;
    font-weight: 900;
    padding-top: 8px;

  }

  .slippage-percentage-section {
    border: 1px solid #7c859f;
    border-radius: 1rem;
  }

  .bg-mode-0n-0ff {
    background: #F0F1F3;
    border-radius: 1rem;
    width: max-content;
    float: right;

  }

  .expert-mode-on-button, .expert-mode-off-button {
    background: transparent;
    outline: none;
    padding: 8px 15px;
    border: none;
    border-radius: 1rem;
    font-size: 14px;
    font-weight: 900;
  }

  .recipient-on-button, .recipient-off-button {
    background: transparent;
    outline: none;
    padding: 8px 15px;
    border: none;
    border-radius: 1rem;
    font-size: 14px;
    font-weight: 900;
  }



  .top-right-swap-countdown {
    font-family: Poppins, sans-serif;
    margin-right: 18px;
    border: 1px solid gray;
    border-radius: 50%;
    font-weight: 800;
    padding: 5px;
    color: gray;
    font-size: 14px;
  }

  .top-right-swap-countdown:hover {
    color: #000;
    border: 1px solid #000;
  }

  .m-22 {
    margin-top: -22px !important;
  }

  .more-info-swap-accordian-info-icons {
    font-size: 17px;
    margin-top: -3px;
    margin-left: 6px;
    color: gray;
  }

  .more-info-swap-accordian-text-1 {
    font-size: 13px;
  }

  .more-info-swap-accordian-text-2 {
    font-family: Poppins, sans-serif;
    font-size: 11px;
    color: gray;
  }

  .more-info-swap-accordian-text-3 {
    font-family: Poppins, sans-serif;
    font-size: 13px;
    font-weight: 700;
  }

  .more-info-swap-accordian-text-4 {
    font-size: 13px;
    color: #7C859F;
    font-weight: 500;
  }

  .more-info-swap-accordian-text-5 {
    font-size: 13px;
    font-weight: 600;
  }

  .more-info-swap-accordian-icons {
    font-size: 25px;
    border: 1px solid #3988f9;
    border-radius: 50%;
    padding: 2px;
    color: rgba(0, 0, 0, 0.685);
  }

  .more-info-swap-accordian-icons:hover {
    background: linear-gradient(268deg, rgba(57, 137, 249, 0.178), rgba(58, 58, 214, 0.253)) 0px 0px no-repeat padding-box padding-box rgba(0, 0, 0, 0);
  }

  .more-info-swap-accordian .accordion-item {
    border: none !important;
    margin-right: 3px;
    margin-left: 3px;
    background-color: #F0F1F3 !important;
    border-radius: 15px !important;

  }

  .more-info-swap-accordian .accordion-button {
    outline: none !important;
    box-shadow: none !important;
    background-color: #F0F1F3 !important;
    padding: 15px;
    border-radius: 15px !important;

  }

  .more-info-swap-accordian .accordion-button:not(.collapsed) {
    color: #000 !important;
  }

  .swap-button-rotate {
    background: linear-gradient(268deg, rgb(57, 136, 249), rgb(59, 58, 214)) 0px 0px no-repeat padding-box padding-box rgba(0, 0, 0, 0);
    color: rgb(255, 255, 255);
    font-size: 50px;
    padding: 10px;
    border-radius: 15px;
    position: relative;
    margin-top: -20px;
  }

  .from-section-swap-top {
    background-color: #F0F1F3;
    margin-right: 3px;
    margin-left: 3px;
    padding: 10px;
    border-radius: 15px;
    border: 1px solid transparent;
  }

  .from-section-swap-top:hover {
    border: 1px solid rgba(128, 128, 128, 0.411);
  }

  .swap-from-image {
    width: 30px;
    height: 30px;
    margin-top: 4px;
  }

  .swap-arrow-icons {
    font-size: 16px;
  }

  .swap-from-btn-max {
    color: rgba(0, 0, 0, 0.815) !important;
    /* background: #0000 linear-gradient(268deg,#3989f952,#3a3ad660) 0 0 no-repeat padding-box; */
    border-color: #0d6efd;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    text-transform: uppercase;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.005rem 0.75rem !important;
    font-size: 11px;
    border-radius: 1rem;
    font-weight: 700;
    border: 1px solid #0D6EFD;
    width: fit-content;

  }

  .swap-from-btn-max:hover {
    color: rgba(0, 0, 0, 0.815) !important;
    background: #0000 linear-gradient(268deg, #3989f952, #3a3ad660) 0 0 no-repeat padding-box;

  }

  .swap-top-banner-section ::placeholder {
    color: #7c859f;
    font-size: 1.4rem;
  }

  .form-control-swap-text {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    box-shadow: none;
    outline: none;

  }

  .swap-section-background {
    background-color: #FFF;
    border-radius: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 20px;
  }

  .swap-section-text-4 {
    font-size: 11px;
    font-weight: 400;
    position: relative;
    top: -5px;
    text-transform: uppercase;
    padding: 0px !important;
    margin: 0px !important;
  }

  .swap-section-text-3 {
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 0px !important;
    margin: 0px !important;
  }

  .swap-section-text-2 {
    color: #7c859f;
    font-size: 1rem;
    font-weight: 700;
    font-family: Poppins, sans-serif;
    letter-spacing: 0.085em;
  }

  .swap-section-text-1 {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: .025em;
    font-weight: 1000;
  }

  .swap-top-banner-section-1 {
    padding-top: 80px;
  }

  .swap-top-banner-section {
    padding-top: 80px;
  }

  .top-left-navbar-icons {
    font-size: 30px;
    margin-left: 10px;
    margin-top: 4px;
  }

  .top-right-swap-graph-icons {
    font-size: 24px;
    margin-right: 18px;
    margin-top: 4px;
    color: gray;
  }

  .top-right-swap-graph-icons:hover {
    color: #000;
  }

  .top-right-swap-settings-icons {
    font-size: 28px;
    margin-right: 18px;
    margin-top: 4px;
    color: gray;
  }

  .top-right-swap-settings-icons:hover {
    color: #000;
  }

  .sidenav {
    height: 100vh;
    width: 240px;
    position: fixed;
    z-index: 999;
    top: 0;
    left: -10px;
    background-color: #fff;
    overflow: hidden;
    transition: 0.5s;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .sidenav .closebtn-sidebar-navbar {
    display: none;
  }

  .openbtn-sidebar-navbar {
    display: none;
  }

  .top-navbar-value {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: .03em;
    font-weight: 600;
    background-color: #FFF;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    padding: 10px;
    border-radius: 1rem;
    color: #000;
  }

  .top-navbar-dropdown {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: .03em;
    font-weight: 600;
    background-color: #FFF;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    padding: 10px 15px;
    border-radius: 1rem;
    color: #000;
  }

  .wallet-button {
    background-color: #FFF;
    border: 1px solid #3988f9;
    border-radius: 15px;
    color: #3988f9;
    font-size: 11px;
    font-weight: 500;
    padding: 10px 15px !important;
    text-transform: uppercase;
    transition: all .3s ease-in-out;
  }

  .wallet-button:hover {
    background-color: #3988f9;
    color: #FFF;
    box-shadow: 0 6px 12px 0 #3988f9
  }

}

/* ============ mobile view ============ */
@media(max-width: 991px) {
  .modal-header-heading {
    position: relative;
    top: -40px;
    padding: 0px;
  }
  .connected-text-send{
    background-color: #EDEEF2;
    border: none;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 10px;
    color: #3988f9;
  }
  .connected-wallet-token-section:hover{
    background-color: #3697f246;
  }
  .connected-text-6{
    font-size: 16px;
    font-weight: 500;
    margin-top: 8px;
  }
  .connected-text-5{
    font-size: 12px;
    font-weight: 500;
  }
  .connected-text-4{
    font-size: 12px;
    font-weight: 900;
    margin-left: 4px;
    margin-bottom: -6px !important;

  }
  .connected-wallet-token-section img {
    width: 30px;
    height: 30px;
  }

  .connected-text-copy {
    background: transparent;
    border: 0px solid rgb(233 133 102) !important;
    margin-right: 10px;
  }

  .connected-copy-icon {
    --tw-text-opacity: 1;
    color: rgb(124 133 159 / var(--tw-text-opacity));
    font-size: 14px;
    margin-bottom: 2px;
  }

  .connected-text-3 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 50px;
    vertical-align: middle;
    font-size: 12px;
  }

  .connected-text-2 {
    font-size: 12px;
    font-weight: 900;
    margin-left: 4px;
  }

  .connected-wallet-metamask {
    width: 20px;
    height: 20px;
  }

  .connected-wallet-display-section {
    background-color: #EDEEF2;
    padding-top: 8px;
    padding-bottom: 10px;
  }

  .connected-reload-button {
    margin-bottom: 4px;
  }

  .connected-text-reload-2 {
    background: transparent;
    font-size: 16px;
    color: #3988f9;
    border: none !important;
    padding-left: 6px;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-right: 6px;
    border-radius: 6px;
  }

  .connected-text-reload {
    background: transparent;
    font-size: 20px;
    color: #3988f9;
    border: none !important;
    margin-left: 12px;
    padding-left: 6px;
    padding-top: 1px;
    padding-bottom: 2px;
    padding-right: 6px;
    border-radius: 6px;
  }

  .connected-text-reload:hover {
    color: #fff;
    background: #3697f2a6;
  }

  .connected-text-add {
    background: transparent;
    font-size: 20px;
    color: #7bddd3;
    border: 1px solid #7bddd3 !important;
    margin-left: 12px;
    padding-left: 6px;
    padding-top: 1px;
    padding-bottom: 2px;
    padding-right: 6px;
    border-radius: 6px;
  }

  .connected-text-add:hover {
    background: #7bddd32c;
    font-size: 20px;
    color: #7bddd3;
    border: 1px solid #7bddd3 !important;
  }

  .connected-text-disconnect {
    background: transparent;
    font-size: 20px;
    color: rgb(233 133 102);
    border: 1px solid rgb(233 133 102) !important;
    margin-left: 12px;
    padding-left: 6px;
    padding-top: 1px;
    padding-bottom: 2px;
    padding-right: 6px;
    border-radius: 6px;
  }

  .connected-text-disconnect:hover {
    background: rgba(233, 133, 102, 0.281);
    font-size: 20px;
    color: rgb(233 133 102);
    border: 1px solid rgb(233 133 102) !important;
  }

  .connected-text-1 {
    font-family: SecularOne;
    font-size: 21px;
    line-height: 17px;
    font-weight: 900;
  }
  .connect-wallet-active {
    border: 1px solid #3988f9 !important;
    background-color: #96bff88e !important;
  }

  .eth-connect-wallet-button, .bsc-connect-wallet-button, .wyzth-connect-wallet-button {
    background-color: transparent;
    border: 1px solid rgba(128, 128, 128, 0.247);
    width: 100%;
    padding-top: 4px;
    border-radius: 12px;
  }

  .connect-wallet-section img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .connect-wallet-text-1 {
    font-size: 12px;
    font-weight: 600;
    margin: 0px;
  }

  .connect-wallet-text-2 {
    font-size: 12px;
    font-weight: 400;
    position: relative;
    top: -4px;
  }

  #myVideo {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    min-width: 100% !important;
    height: 100% !important;
    opacity: 0.3;
  }

  .footer-bottom-section {
    padding-top: 40px !important;
    padding-bottom: 40px;
  }

  .hero-banner-section-1 {
    font-family: "SecularOne";
    font-size: 30px;
    color: #FFF;
    margin-bottom: 0px;
  }

  .hero-banner-section-2 {
    font-family: "Jost";
    font-size: 22px;
    color: #FFF;
  }

  .card-bottom-section img {
    width: 50%;
    height: 180px;
    margin: auto;
  }

  .two-row-heading-style {
    color: #f6de7f;
  }

  /*==========================================BANNER================================================================*/





  .container-two-content-styling h1 {
    font-size: 1.5em;
    /* 	margin-bottom: 2em; */
    text-transform: uppercase;
    letter-spacing: 10px;
    text-align: center;
    font-family: Lora, serif;
    font-weight: 200;
  }

  .heading-before-after::before {
    content: '─';
    margin-right: 0.6em;
    color: #3988f9;
  }

  .heading-before-after::after {
    content: '─';
    margin-left: 0.6em;
    color: #3988f9;
  }

  .boxes {
    display: flex;
    justify-content: space-evenly;
  }

  .boxes:last-child {
    margin-top: 120px;
  }

  .box {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background: linear-gradient(180deg, rgba(35, 48, 75, .32) 0, #071E64 100%);
    -webkit-backdrop-filter: blur(32px);
    backdrop-filter: blur(32px);
    border-radius: 32px;

    position: relative;
    text-align: center;
    margin: 1em;
    z-index: 3;

  }

  .second-section-card-images-1 {
    width: 120px;
    margin-top: -40px;
    padding-bottom: 50px;
  }

  .second-section-card-images-2 {
    width: 170px;
    margin-top: -70px;
    padding-bottom: 30px;
  }

  .box:last-child {
    border-right: 0;
  }

  .box .icon {
    font-size: 6em;
    color: #3988f9;
    bottom: 30px;
    position: relative;

  }

  .box h2 {
    font-size: 1.5em;
    margin-bottom: 1em;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-weight: 600;

  }

  .box .piece-count {
    font-style: italic;
    margin-bottom: 1.2em;
    letter-spacing: 1px;
    font-family: Lora, serif;
    font-size: 1.2em;
    font-weight: 600;
  }

  .piece-count-para {
    font-size: 1em;
  }

  /* ====================================FOOTER========================================== */
  .mobile-image-styling-banner-page {
    width: 100%;
    height: 700px;
  }

  .footer-bottom-section img {
    width: 40%;
  }

  .footer-bottom-section h1 {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 24px;
  }

  .footer-icons-heading:hover {
    color: rgb(255, 255, 255);
  }

  .footer-community-icons {
    font-size: 18px;
    color: white;
    margin-bottom: 4px;
    margin-right: 10px;
    transition: transform .2s;
    /* Animation */
  }

  .footer-icons-heading:hover .footer-community-icons {
    transform: scale(1.4);
  }

  .footer-icons-heading {
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-bottom: 8px;
  }

  .top-hero-section-heading {
    font-family: "Jost";
    font-size: 30px;
    line-height: 60px;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .top-hero-section-star-image {
    width: 40px;
    height: 40px;
    margin-top: -70px;
    margin-right: 10px;
  }

  .home-card-section .card {
    width: 100%;
    background: #071E64;
    border: 0px;
    border-radius: 15px;
    margin-bottom: 2em;
    margin-top: 1em;
  }

  .home-card-section .card img {
    width: 75px;
    height: 75px;
    position: absolute;
  }

  .launch-button-navbar {
    font-family: "Jost";
    color: #3697F2 !important;
    background-color: transparent;
    border-radius: 10rem;
    border: 1px solid #3697F2;
    padding: 10px 40px !important;
    width: fit-content;
    font-weight: 600;
    font-size: 14px !important;
    letter-spacing: 2px !important;
    transition: all .3s ease-in-out;
  }

  .launch-button-navbar:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px 0 #3697F2
  }

  .swap-button-card {
    font-family: "Jost";
    color: #3697F2 !important;
    background-color: transparent;
    border-radius: 10rem;
    border: 1px solid #3697F2;
    padding: 10px 40px !important;
    width: fit-content;
    font-weight: 700;
    font-size: 18px !important;
    letter-spacing: 2px !important;
    transition: all .3s ease-in-out;
  }

  .swap-button-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px 0 #3697F2
  }

  .launch-button-herobanner {
    font-family: "Jost";
    color: #fff;
    background-color: #3697f2a6;
    border-radius: 10rem;
    padding: 10px 40px !important;
    width: fit-content;
    font-weight: 700;
    transition: transform 3s;
    /* Animation */
    margin: auto;
  }

  .launch-button-herobanner:hover {
    background: linear-gradient(56deg, #61dafb 0%, #45d4df 46%, #cbe1f6 100%);
    color: #fff;
    transform: scale(1.20);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .launch-button-herobanner .launch-button-herobanner-image-1 {
    display: none;
  }

  .launch-button-herobanner:hover .launch-button-herobanner-image-1 {
    display: none;
  }

  .launch-button-herobanner .launch-button-herobanner-image-2 {
    display: none;
  }

  .launch-button-herobanner:hover .launch-button-herobanner-image-2 {
    display: none;
  }

  .dashboard-top-section .swiper-button-next, .swiper-button-prev {
    display: none !important;
  }

  .top-dashboard-section-table {
    display: none;
  }

  .dashboard-table-swap-button {
    background: transparent;
    outline: none;
    border: 1px solid #3988f9;
    padding: 8px 16px;
    border-radius: 12px;
    font-size: 11px;
    font-weight: 600;
  }

  .dashboard-table-swap-button:hover {
    background: #3989f944;
    border: 1px solid #3988f9;
  }

  .dashboard-table-liquidity-button {
    background: transparent;
    outline: none;
    border: 1px solid #7B48E8;
    padding: 8px 16px;
    border-radius: 12px;
    font-size: 11px;
    font-weight: 600;
  }

  .dashboard-table-liquidity-button:hover {
    background: #7b48e838;
    border: 1px solid #7B48E8;
  }

  .dashboard-section-swap-button {
    background: transparent;
    outline: none;
    border: 1px solid #3988f9;
    padding: 4px 1px;
    width: 100%;
    border-radius: 12px;
    font-size: 11px;
    font-weight: 600;
  }

  .dashboard-section-swap-button:hover {
    background: #3989f944;
    border: 1px solid #3988f9;
  }

  .dashboard-section-liquidity-button {
    background: transparent;
    outline: none;
    border: 1px solid #7B48E8;
    padding: 4px 1px;
    width: 100%;
    border-radius: 12px;
    font-size: 11px;
    font-weight: 600;
  }

  .dashboard-section-liquidity-button:hover {
    background: #7b48e838;
    border: 1px solid #7B48E8;
  }

  .dashboard-section-text-5 {
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 0rem !important;
  }

  .dashboard-section-text-4 {
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 0.2rem !important;
  }

  .dashboard-section-text-3 {
    font-size: 16px;
    letter-spacing: .02em;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 0px !important;
  }

  .staking-section-text-5 {
    font-size: 15px;
    font-weight: 400;
    color: #7c859f;
  }

  .staking-section-text-4 {
    font-size: 16px;
    margin-bottom: 0.6rem;
    font-weight: 500;
  }

  .staking-info-icon-2 {
    font-size: 16px;
    color: #3988f9;
    margin-bottom: 4px;
    margin-left: 4px;
  }

  .staking-section-text-3 {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: .03em;
    font-weight: 500;
    color: #7c859f;
  }

  .your-stake-details-section {
    background-color: #E6F2F7;
    padding: 12px 22px;
    border: 1px solid #67cefa;
    border-radius: 1rem;
  }

  .staking-section-text-2 {
    font-size: 15px;
    line-height: 25px;
    letter-spacing: -.01em;
    font-weight: 500;
  }

  .staking-section-addwyzth-button {
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #000 !important;
    background-color: transparent;
    border: 1px solid gray;
    padding: 6px 14px;
    border-radius: 1rem;
  }

  .metamask-logo {
    height: 18px;
    width: 18px;
    margin-bottom: 2px;
  }

  .staking-reload-icon {
    font-size: 16px;
    color: #7c859f;
  }

  .staking-info-icon {
    font-size: 16px;
    color: #3988f9;
  }

  .staking-section-text-1 {
    font-size: 12px;
    font-weight: 400;
    color: #7c859f;
  }

  .volume-breadcrumb a {
    color: #7c859f;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }

  .volume-breadcrumb a:hover {
    border-bottom: 1px solid gray;
  }

  .liquidity-breadcrumb a {
    color: #7c859f;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }

  .liquidity-breadcrumb a:hover {
    border-bottom: 1px solid gray;
  }

  .dashboard-section-week-button-1, .dashboard-section-all-button-1, .dashboard-section-week-button-2, .dashboard-section-all-button-2 {
    background: transparent;
    outline: none;
    border: 1px solid rgba(128, 128, 128, 0.658);
    padding: 6px 14px;
    border-radius: 12px;
    font-size: 11px;
    font-weight: 600;
  }

  .dashboard-section-heading-1 {
    font-family: Acme;
    font-size: 16px;
    font-weight: 600;
  }

  .dashboard-section-heading-2 {
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
  }

  .dashboard-section-heading-3 {

    font-size: 12px;
    margin-left: 10px;
    font-weight: 500;
  }

  .dashboard-top-section-Swiper-Slide-1:hover .dashboard-section-text-1 {
    color: #FFF !important;
  }

  .dashboard-top-section-Swiper-Slide-1:hover {
    background-color: #F8B35A;
    color: #FFF !important;
  }

  .dashboard-top-section-Swiper-Slide-2:hover .dashboard-section-text-1 {
    color: #FFF !important;
  }

  .dashboard-top-section-Swiper-Slide-2:hover {
    background-color: #7B48E8;
    color: #FFF !important;
  }

  .dashboard-top-section-Swiper-Slide-3:hover .dashboard-section-text-1 {
    color: #FFF !important;
  }

  .dashboard-top-section-Swiper-Slide-3:hover {
    background-color: #ED6B82;
    color: #FFF !important;
  }

  .dashboard-top-section-Swiper-Slide-4:hover .dashboard-section-text-1 {
    color: #FFF !important;
  }

  .dashboard-top-section-Swiper-Slide-4:hover {
    background-color: #3697F2;
    color: #FFF !important;
  }

  .dashboard-section-text-2 {
    font-size: 15px;
    letter-spacing: -.02em;
    font-weight: 900;
    margin-bottom: 0px;
  }

  .swiper-slide-icons-info {
    font-size: 14px;
    margin-bottom: 4px;
    margin-left: 4px;
  }

  .dashboard-section-text-1 {
    font-size: 12px;
    letter-spacing: .03em;
    font-weight: 700;
    color: gray;
    margin-bottom: 0.8rem;
  }

  .swiper-slide-icons-4 {
    font-size: 40px;
    background-color: #E7F1FB;
    padding: 25px 6px;
    height: 100%;
    margin-right: 10px;
    border-radius: 30px;
    color: #3697F2;
  }

  .swiper-slide-icons-3 {
    font-size: 40px;
    background-color: #FAEDEF;
    padding: 25px 6px;
    height: 100%;
    margin-right: 10px;
    border-radius: 30px;
    color: #ED6B82;
  }

  .swiper-slide-icons-2 {
    font-size: 40px;
    background-color: #EFE9FA;
    padding: 25px 6px;
    height: 100%;
    margin-right: 10px;
    border-radius: 30px;
    color: #7B48E8;
  }

  .swiper-slide-icons-1 {
    font-size: 40px;
    background-color: #FCF4EB;
    padding: 25px 6px;
    height: 100%;
    margin-right: 10px;
    border-radius: 30px;
    color: #F8B35A;
  }

  .Swiper-Slide-1 {
    background: #000;
  }

  #wallet-top-banner-section ::placeholder {
    font-size: 15px;
  }

  .form-control-wallet-section {
    box-shadow: none;
    outline: none;
    display: block;
    width: 100%;
    padding: 0.375rem 0.15rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0rem;
    border-left: none;
    width: 40%;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  #wallet-top-banner-section .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.55rem;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    border-radius: 0px;
    border-right: none;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  .wallet-button-grid-list-section {
    border: 1px solid rgba(0, 0, 0, 0.473);
    width: fit-content;
    border-radius: 16px;
    display: flex;
  }

  .wallet-button-grid-list-1 {
    font-size: 14px;
    padding-bottom: 6px;
    padding-left: 12px;
    padding-top: 2px;
    border: none;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .wallet-button-grid-list-2 {
    font-size: 14px;
    padding-bottom: 6px;
    padding-right: 12px;
    padding-top: 2px;
    border: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  #wallet-top-banner-section .list-view .wallet-section-text-6 {
    display: none;
  }

  #wallet-top-banner-section .list-view .symboloverview-widget-section-top {
    display: none;
  }

  #wallet-top-banner-section .list-view .percentage-widget-section-top {
    display: none;
  }

  #wallet-top-banner-section .list-view .price-widget-section-top {
    display: none;
  }

  #wallet-top-banner-section .list-view .default-coin-name-section-2 {
    display: none;
  }

  .truncate-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 60px;
    vertical-align: middle;
  }

  .wallet-section-text-8 {
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
  }

  .list-view-all-headings {
    margin: 0px;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: .03em;
    font-weight: 700;
    color: #7c859f;
  }

  .wallet-section-text-9 {
    font-weight: 900;
    margin-left: 4px;
    font-size: 15px;
    font-family: sans-serif;
  }


  #wallet-top-banner-section .list-view .symboloverview-widget-section {
    height: 100px !important;
    margin-top: -40px;
  }

  #wallet-top-banner-section .grid-container .wallet-section-text-4 {
    font-size: 24px;
    letter-spacing: .025em;
    font-weight: 600;
    margin-bottom: 0px;
  }

  #wallet-top-banner-section .list-view .wallet-section-text-4 {
    font-size: 16px;
    letter-spacing: .025em;
    font-weight: 600;
    margin-bottom: 0px;
  }

  #wallet-top-banner-section .list-view .wallet-section-bottom-border {
    display: none;
  }

  #wallet-top-banner-section .grid-container .list-view-all-headings {
    display: none;
  }

  #wallet-top-banner-section .grid-container .top-amount-section {
    display: none;
  }

  #wallet-top-banner-section .list-view .top-amount-section {
    display: block;
  }

  #wallet-top-banner-section .list-view .list-view-all-headings {
    display: block;
  }

  #wallet-top-banner-section .list-view .default-coin-name-section-2 {
    padding-left: 30px;
  }

  #wallet-top-banner-section .grid-container .default-coin-name-section-2 {
    padding-left: 10px;
  }

  .wallet-section-bottom-border {
    border-top: 1px solid rgba(0, 0, 0, 0.247);
  }

  .wallet-section-text-7 {
    font-size: 18px;
    letter-spacing: .03em;
    font-weight: 600;
    color: #7c859f;
  }

  .wallet-section-buttons-section:hover .wallet-section-text-6 {
    color: rgba(0, 0, 0, 0.808);
  }

  .wallet-section-buttons-section:hover .wallet-section-buttons {
    color: rgba(0, 0, 0, 0.808);
  }

  .wallet-section-text-6 {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: .04em;
    font-weight: 500;
    color: rgb(128, 128, 128);
    margin-top: 6px !important;
  }

  .wallet-section-buttons {
    background-color: #d8d9db;
    color: rgba(128, 128, 128, 0.719);
    border: 0px;
    width: 3rem !important;
    border-radius: 50px;
    font-size: 20px;
    padding-bottom: 8px;
  }


  .wallet-section-text-5 {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: .03em;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .wallet-section-text-3 {
    position: relative;
    top: -8px;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: .03em;
    font-weight: 450;
    color: #7c859f;
  }

  .wallet-section-text-2 {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: .03em;
    font-weight: 500;
  }

  .wallet-section-logo {
    width: 35px;
    height: 35px;
  }

  .wallet-section-card-button-section {
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.247);
    margin-bottom: 10px;
  }

  .wallet-section-card {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #FFF;
    padding: 10px 20px;
    border-radius: 15px;
    border: 1px solid transparent;
  }

  .wallet-section-card:hover {
    border: 1px solid rgba(128, 128, 128, 0.411);
  }

  .disconnect-list-button {
    border: 1px solid rgb(233 133 102);
    font-size: 14px;
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 6px;
    color: rgb(233 133 102);
    border-radius: 10px;
    background: transparent;
  }

  .disconnect-list-button:hover {
    border: 1px solid rgb(233 133 102);
    color: rgb(233 133 102);
  }

  .reload-list-button {
    border: 1px solid #3697F2;
    font-size: 14px;
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 6px;
    color: #3697F2;
    border-radius: 10px;
    background: transparent;
  }

  .reload-list-button:hover {
    border: 1px solid #3697F2;
    color: #3697F2;
  }

  .connect-wallet-list-button {
    background: #3697F2 !important;
    color: #fff !important;
    font-size: 14px;
    font-weight: 700;
    border-radius: 14px;
    padding: 10px 18px;
    border: none;
  }

  .connect-wallet-list-button:hover {
    background: #3697F2 !important;
    color: #fff;
    border: none;
  }

  .wallet-section-text-1 {
    font-size: 15px;
    line-height: 21px;
    letter-spacing: .03em;
    font-weight: 500;
  }

  #wallet-top-banner-section .card {
    background-color: transparent;
    border: none;
  }

  #wallet-top-banner-section .card-body {
    padding: 0px !important;
  }

  .more-info-send-accordian .accordion-item {
    border: none !important;
    margin-right: 3px;
    margin-left: 3px;
    background-color: #F0F1F3 !important;
    border-radius: 15px !important;

  }

  .more-info-send-accordian .accordion-button {
    outline: none !important;
    box-shadow: none !important;
    background-color: #F0F1F3 !important;
    padding: 15px 15px;
    border-radius: 12px !important;
  }

  .more-info-send-accordian .accordion-button:not(.collapsed) {
    color: #000;
  }

  .send-section-address ::placeholder {
    color: #7c859f;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .more-info-deposit-accordian-text-1 {
    font-size: 8px;
    color: #7C859F;
    font-weight: 400;
  }

  .more-info-deposit-accordian-text-2 {
    font-size: 12px;
    letter-spacing: -.01em;
    font-weight: 500;
    font-family: Poppins, sans-serif;
  }

  .balance-text-1 {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: .03em;
    font-weight: 500;
    color: #7c859f;
  }

  .from-section-deposit-top {
    background-color: #F0F1F3;
    padding: 10px;
    border-radius: 15px;
    border: 1px solid transparent;
  }

  .deposit-switch-tabs-section-button {
    border: 1px solid #7c859f2d;
    border-radius: 12px;
    padding: 4px;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .deposit-switch-tabs-section .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #FFF;
    background-color: #3988f9;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 600;
    padding: 10px;
    letter-spacing: 1px;
    color: #FFF !important;
  }

  .deposit-switch-tabs-section .nav-pills .nav-link {
    font-size: 12px;
    font-weight: 600;
    padding: 10px;
    letter-spacing: 1px;
    border-radius: 12px;
    color: #000 !important;
  }

  .token-select-section {
    background-color: #ECECEC;
  }

  #Swap-coin-select-section .list-group-item {
    padding-top: 4px;
    padding-bottom: 0px;
    text-decoration: none;
    background-color: transparent;
    border: 0px;
  }

  #Swap-coin-select-section .list-group-item:hover {
    background-color: #FFF;
  }

  .token-select-image {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-bottom: 2px;
  }

  .token-select-section-icon {
    font-size: 24px;
    margin-bottom: 2px;
  }

  .token-select-section-text-1 {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }

  .token-select-section-text-2 {
    font-size: 10px;
    font-weight: 400;
    position: relative;
    top: -9px;
    text-transform: uppercase;
  }

  .swap-select-form-filter-section {
    margin-left: 4px;
    margin-right: 4px;
    border: 1px solid #7c859f;
    border-radius: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .swap-select-form-filter-button-1, .swap-select-form-filter-button-2, .swap-select-form-filter-button-3, .swap-select-form-filter-button-4, .swap-select-form-filter-button-5 {
    background: transparent;
    outline: none;
    border: none;
    padding: 10px 10px;
    width: 20%;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 600;
  }

  #Swap-coin-select-section .btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.55em 0.55em;
    color: #000;
    border: 1px solid #000;
    border-radius: 0.375rem;
    opacity: .5;
    margin-right: 10px;
    box-shadow: none !important;
  }

  #Swap-coin-select-section ::placeholder {
    font-size: 15px;
    font-weight: 400;
    text-transform: capitalize;
  }

  .swap-select-form {
    outline: none;
    display: block;
    width: 100%;
    padding: 0.675rem 0.75rem;
    padding-bottom: 14px;
    font-size: 1rem;
    font-weight: 400;
    color: #212529;
    background-color: #F0F1F3;
    border: 1px solid #ced4da;
    border-radius: 1.5rem;
    padding-left: 30px;
  }

  .swap-coin-select-text-1 {
    font-family: Acme;
    font-style: italic;
    font-size: 22px;
    letter-spacing: 2px;
    font-weight: 800;
    text-transform: uppercase;
    padding: 0px !important;
    margin: 0px !important;
    padding-left: 10px !important;
  }

  .slippage-settings-section .modal-dialog {
    max-width: 400px;

  }

  /* .modal-backdrop {
      background-color: transparent;
   } */

  .slippage-settings-section .modal-content {
    padding: 10px;
    border-radius: 15px;
  }

  .slippage-settings-text-1 {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: .03em;
    font-weight: 700;
  }

  .slippage-settings-text-2 {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: .03em;
    font-weight: 700;
    color: #7c859f;
  }

  .slippage-icon-text-1 {
    font-size: 16px;
    color: #7c859f;
    margin-bottom: 3px;
    margin-left: 6px;
  }

  .slippage-settings-button-05, .slippage-settings-button-3, .slippage-settings-button-1 {
    background: transparent;
    outline: none;
    border: 1px solid #7c859f;
    padding: 8px 20px;
    border-radius: 1rem;
    margin-right: 8px;
    font-size: 14px;
    font-weight: 900;
  }

  .fee-normal-button, .fee-fast-button, .fee-instant-button {
    background: transparent;
    outline: none;
    border: 1px solid #7c859f;
    padding: 8px 20px;
    border-radius: 1rem;
    margin-right: 8px;
    font-size: 14px;
    font-weight: 900;
  }



  .slippage-settings-section ::placeholder {
    color: rgba(0, 0, 0, 0.616) !important;
    font-size: 14px;
    font-weight: 900;
  }

  .form-slippage-percentage {
    width: 80%;
    padding: 8px 0px;
    text-align: right;
    font-size: 14px;
    font-weight: 900;
    color: #000;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  }

  .slippage-settings-text-3 {
    font-size: 14px;
    font-weight: 900;
    padding-top: 8px;

  }

  .slippage-percentage-section {
    border: 1px solid #7c859f;
    border-radius: 1rem;
  }

  .bg-mode-0n-0ff {
    background: #F0F1F3;
    border-radius: 1rem;
    width: max-content;
    float: right;

  }

  .expert-mode-on-button, .expert-mode-off-button {
    background: transparent;
    outline: none;
    padding: 8px 15px;
    border: none;
    border-radius: 1rem;
    font-size: 14px;
    font-weight: 900;
  }

  .recipient-on-button, .recipient-off-button {
    background: transparent;
    outline: none;
    padding: 8px 15px;
    border: none;
    border-radius: 1rem;
    font-size: 14px;
    font-weight: 900;
  }

  .top-right-swap-countdown {
    font-family: Poppins, sans-serif;
    margin-right: 18px;
    border: 1px solid gray;
    border-radius: 50%;
    font-weight: 600;
    padding: 5px;
    color: gray;
    font-size: 12px;
  }

  .top-right-swap-countdown:hover {
    color: #000;
    border: 1px solid #000;
  }

  .m-22 {
    margin-top: -16px !important;
  }

  .more-info-swap-accordian-info-icons {
    font-size: 14px;
    margin-top: -3px;
    margin-left: 6px;
    color: gray;
  }

  .more-info-swap-accordian-text-1 {
    font-size: 10px;
  }

  .more-info-swap-accordian-text-2 {
    font-family: Poppins, sans-serif;
    font-size: 10px;
    color: gray;
  }

  .more-info-swap-accordian-text-3 {
    font-family: Poppins, sans-serif;
    font-size: 11px;
    font-weight: 700;
  }

  .more-info-swap-accordian-text-4 {
    font-size: 11px;
    color: #7C859F;
    font-weight: 500;
  }

  .more-info-swap-accordian-text-5 {
    font-size: 11px;
    font-weight: 600;
  }

  .more-info-swap-accordian-icons {
    font-size: 22px;
    border: 1px solid #3988f9;
    border-radius: 50%;
    padding: 2px;
    color: rgba(0, 0, 0, 0.685);
  }

  .more-info-swap-accordian-icons:hover {
    background: linear-gradient(268deg, rgba(57, 137, 249, 0.178), rgba(58, 58, 214, 0.253)) 0px 0px no-repeat padding-box padding-box rgba(0, 0, 0, 0);
  }

  .more-info-swap-accordian .accordion-item {
    border: none !important;
    margin-right: 3px;
    margin-left: 3px;
    background-color: #F0F1F3 !important;
    border-radius: 15px !important;

  }

  .more-info-swap-accordian .accordion-button {
    outline: none !important;
    box-shadow: none !important;
    background-color: #F0F1F3 !important;
    padding: 15px;
    border-radius: 15px !important;

  }

  .more-info-swap-accordian .accordion-button:not(.collapsed) {
    color: #000 !important;
  }

  .swap-button-rotate {
    background: linear-gradient(268deg, rgb(57, 136, 249), rgb(59, 58, 214)) 0px 0px no-repeat padding-box padding-box rgba(0, 0, 0, 0);
    color: rgb(255, 255, 255);
    font-size: 30px;
    padding: 6px;
    border-radius: 12px;
    position: relative;
    margin-top: -20px;
  }

  .from-section-swap-top {
    background-color: #F0F1F3;
    margin-right: 3px;
    margin-left: 3px;
    padding: 10px;
    border-radius: 15px;
    border: 1px solid transparent;
  }

  .from-section-swap-top:hover {
    border: 1px solid rgba(128, 128, 128, 0.411);
  }

  .swap-from-image {
    width: 25px;
    height: 25px;
    margin-top: 4px;
  }

  .swap-arrow-icons {
    font-size: 14px;
  }

  .swap-from-btn-max {
    color: rgba(0, 0, 0, 0.815) !important;
    /* background: #0000 linear-gradient(268deg,#3989f952,#3a3ad660) 0 0 no-repeat padding-box; */
    border-color: #0d6efd;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    text-transform: uppercase;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.05rem 0.75rem !important;
    font-size: 11px;
    border-radius: 1rem;
    font-weight: 700;
    border: 1px solid #0D6EFD;
    width: fit-content;

  }

  .swap-from-btn-max:hover {
    color: rgba(0, 0, 0, 0.815) !important;
    background: #0000 linear-gradient(268deg, #3989f952, #3a3ad660) 0 0 no-repeat padding-box;

  }

  .swap-top-banner-section ::placeholder {
    color: #7c859f;
    font-size: 1rem;
  }

  .form-control-swap-text {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    box-shadow: none;
    outline: none;

  }

  .swap-section-background {
    background-color: #FFF;
    border-radius: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 20px;
  }

  .swap-section-text-4 {
    font-size: 11px;
    font-weight: 400;
    position: relative;
    top: -5px;
    text-transform: uppercase;
    padding: 0px !important;
    margin: 0px !important;
  }

  .swap-section-text-3 {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 0px !important;
    margin: 0px !important;
  }

  .swap-section-text-2 {
    color: #7c859f;
    font-size: 1rem;
    font-weight: 600;
    font-family: Poppins, sans-serif;
    letter-spacing: 0.085em;
  }

  .swap-section-text-1 {
    font-size: 16px;
    line-height: 36px;
    letter-spacing: .025em;
    font-weight: 600;
  }

  .swap-top-banner-section-1 {
    padding-top: 50px;
  }

  .swap-top-banner-section {
    padding-top: 50px;
  }

  .top-left-navbar-icons {
    font-size: 18px;
    margin-left: 6px;
    margin-top: 8px;
  }

  .top-right-swap-graph-icons {
    font-size: 20px;
    margin-right: 16px;
    margin-top: 4px;
    color: gray;
  }

  .top-right-swap-settings-icons {
    font-size: 20px;
    margin-right: 10px;
    margin-top: 4px;
    color: gray;
  }

  .sidenav {
    height: 100vh;
    width: 0px;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: #fff;
    border-radius: 25PX;
    overflow-x: hidden;
    transition: 0.5s;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  }

  .top-navbar-value {
    font-size: 10px;
    line-height: 18px;
    letter-spacing: .03em;
    font-weight: 600;
    background-color: #FFF;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    padding: 8px;
    border-radius: 1rem;
    color: #000;
  }

  .top-navbar-dropdown {
    font-size: 10px;
    line-height: 18px;
    letter-spacing: .03em;
    font-weight: 600;
    background-color: #FFF;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    padding: 8px;
    border-radius: 1rem;
    color: #000;
  }

  .wallet-button {
    background-color: #FFF;
    border: 1px solid #3988f9;
    border-radius: 15px;
    color: #3988f9;
    font-size: 11px;
    font-weight: 500;
    padding: 4px 8px !important;
    text-transform: uppercase;
    transition: all .3s ease-in-out;
    margin-top: 6px;
  }

  .wallet-button:hover {
    background-color: #3988f9;
    color: #FFF;
    box-shadow: 0 6px 12px 0 #3988f9
  }



}




.list-view .row>[class*=col-] {
  max-width: 100%;
  flex: 0 0 100%;
}

.list-view .card {
  flex-direction: row;
}

@media (max-width: 5.98px) {
  .list-view .card {
    flex-direction: column;
  }
}

.list-view .card>.card-img-top {
  width: auto;
}

.list-view .card .card-body {
  display: inline-block;
}

.slippage-settings-color {
  background: #0000 linear-gradient(268deg, #3989f9be, #3a3ad64f) 0 0 no-repeat;
}

.on-off-bg-color {
  background: #0000 linear-gradient(268deg, #3989f9be, #3a3ad64f) 0 0 no-repeat;
}

.on-off-bg-color1 {
  background: #0000 linear-gradient(268deg, #3989f9be, #3a3ad64f) 0 0 no-repeat;
}

.swap-select-form-filter-button-color {
  background: #3988f9;
  color: #FFF;
}

.navlink-active-color {
  background: #0000 linear-gradient(268deg, #3988f9, #3b3ad6) 0 0 no-repeat padding-box;
  color: #FFF !important;
}

.dashboard-filter-button-color {
  border-color: #3988f9;
  background: #3988f9;
  color: #FFF;
}

.volume-breadcrumb-active {
  color: #000 !important;
  border-bottom: 1px solid gray;
}

.liquidity-breadcrumb-active {
  color: #000 !important;
  border-bottom: 1px solid gray;
}



/**=====================
    30. Data-table CSS Start
==========================**/
.token-dashboard-table-image {
  width: 25px;
  height: 25px;
  margin-bottom: 5px;
  margin-right: 5px;
}

.table-responsive {
  overflow-x: visible !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button:active {
  background: #3697F2;
  color: #fff !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.dataTables_wrapper .dataTables_length select {
  background-color: #fff;
  border-color: #e6edef;
  color: #2c323f;
  padding: 0 10px;
  margin: 0 10px;
  height: 2.7142em;
}

/* .dataTables_wrapper table.dataTable tbody td, .dataTables_wrapper table.dataTable tbody th {
  background-color: #fff;
} */


.dataTables_wrapper table.dataTable th, .dataTables_wrapper table.dataTable td {
  padding: 0.75rem;
}

.dataTables_wrapper .dataTables_filter {
  margin-bottom: 25px;
  margin-left: 15px;
}

.dataTables_wrapper .dataTables_filter input[type="search"] {
  border: 0px solid #efefef;
  padding: 0 10px;
  margin-left: 10px;
  height: 37px;
  border-radius: 0;
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
  bottom: 6px !important;
}

.dashboard-section-table table thead tr th {
  color: #7c859f !important;
  font-size: 13px;

}

.dashboard-section-table table tbody tr {
  font-weight: 550;
}

/* .dashboard-section-table table tbody tr td:nth-child(1) {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding-left: 20px !important;
}

.dashboard-section-table table tbody tr td:nth-last-child(1) {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  float: center !important;
} */
.dashboard-section-table table thead tr th:nth-last-child(1) {
  width: 20% !important;
}





table.dataTable thead .sorting:before, table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 11px;
  display: block;
  opacity: 0.3;
  font-size: 1.5em;
}




table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 1.2em;
  display: block;
  opacity: 0.3;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 1em;
  content: "\2303";
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 1em;
  content: "\2304";
}

table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
  opacity: 1;
}

table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  opacity: 0;
}




/*
 * Table styles
 */
table.dataTable {
  width: 100%;
  margin: 0 auto;
}

table.dataTable tbody tr {
  background-color: #ffffff;
}

table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
  background-color: #f6f6f6;
}

.dataTables_wrapper .dataTables_length {
  float: left;
}

.dataTables_wrapper .dataTables_filter {
  float: right;
  text-align: right;
}



.dataTables_wrapper .dataTables_info {
  clear: both;
  float: left;
  padding-top: 1.755em;
}

.dataTables_wrapper .dataTables_paginate {
  float: right;
  text-align: center;
  padding-top: 1.25em;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  *cursor: hand;
  color: #333 !important;
  border: 0px solid transparent;
  border-radius: 2px;
}

.swap-coundown-timer {
  font-size: 12px;
}


.button-container-2 {
  position: relative;
  width: 130px;
  height: 40px;
  overflow: hidden;
  border: 1px solid #3988f9;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  transition: 0.5s;
  letter-spacing: 1px;
  border-radius: 12px;
}

.button-container-2 a {
  width: 101%;
  height: 100%;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 13px;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  padding-top: 10px;
  letter-spacing: 1px;
  font-weight: bold;
  background: #3988f9;
  -webkit-mask: url("https://raw.githubusercontent.com/robin-dela/css-mask-animation/master/img/urban-sprite.png");
  mask: url("https://raw.githubusercontent.com/robin-dela/css-mask-animation/master/img/urban-sprite.png");
  -webkit-mask-size: 3000% 100%;
  mask-size: 3000% 100%;
  border: none;
  color: #fff;
  cursor: pointer;
  -webkit-animation: ani2 0.7s steps(29) forwards;
  animation: ani2 0.7s steps(29) forwards;
}

.button-container-2 a:hover {
  -webkit-animation: ani 0.7s steps(29) forwards;
  animation: ani 0.7s steps(29) forwards;
}

.mas {
  text-transform: uppercase;
  position: absolute;
  color: #3988f9;
  text-align: center;
  width: 101%;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  position: absolute;
  font-size: 13px;
  margin-top: 10px;
  overflow: hidden;
  font-weight: bold;
}

@-webkit-keyframes ani {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }

  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@keyframes ani {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }

  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@-webkit-keyframes ani2 {
  from {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }

  to {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}

@keyframes ani2 {
  from {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }

  to {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}

#stylized{
  display: none;
}

#canvaimage{
  display: none;
}

.not-allowed{
  cursor: not-allowed !important;
  
}